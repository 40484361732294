import { Partner } from "@/models/PartnerModel";
import { Language } from "@/models/LanguageModel";
import {EMPTY_GUID} from "@/utils/GuidUtil";

export class AppInstance {
    id = EMPTY_GUID;
    name= '';
    partnerId = EMPTY_GUID;
    subdomain = '';
    languageId = EMPTY_GUID;
    allowStorytelling = true;
    displayScriptureRefOnly = false;
    languageFile = '';
    languageFilename = '';
    scriptureFile = '';
    scriptureFilename = '';
    incomplete = true;
    language = {} as Language;
    partner = {} as Partner;
    suspended = false;
}